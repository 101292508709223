import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

type Auth = {
    name: string;
}

type AuthContext = {
    auth: Auth | undefined,
    loading: boolean,
    onLogin: (token: string) => Promise<Error | undefined>
    onLogout: () => void
}

const AuthContext = createContext<AuthContext>({
        auth: undefined,
        loading: true,
        onLogin: (s) => (Promise.resolve(undefined)),
        onLogout: () => {
        }
    }
)

export const AuthProvider = ({children}: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState<Auth | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true)


    useEffect(() => {
        setLoading(true)
        axios.get("/api/v1/auth/status").then(res => {
            if (res.status === 200) {
                setAuth({name: res.data.name})
            }
        }).catch((err) => {
        }).finally(
            () => {
                setLoading(false)
            }
        );
    }, [])

    const handleLogin = async (token: string): Promise<Error | undefined> => {
        return axios.post(`/api/v1/login?token=${token}`)
            .then((res) => {
                setAuth({name: res.data.name})
                navigate("/")
            })
            .catch((err) => {
                return err
            });
    }

    const handleLogout = () => {
        axios.post("/api/v1/logout")
            .then(() => setAuth(undefined))

    };

    const value = {
        auth,
        loading,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext)
}
