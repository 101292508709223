import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Order from "./Order";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Login} from "./Login";
import {IKOMNavbar} from "../component/IKOMNavbar";
import {Logout} from "./Logout";
import {Management} from "./Management";
import {AuthProvider} from "../component/AuthProvider";
import {ProtectedRoute} from "../component/ProtectedRoute";
import {Home} from "./Home";
import Status from "./Status";

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_BACKEND

export const App = () => {

    return (<>
        <BrowserRouter>
            <AuthProvider>
                <IKOMNavbar/>
                <Routes>
                    <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/order" element={<ProtectedRoute><Order/></ProtectedRoute>}/>
                    <Route path="/status" element={<ProtectedRoute><Status/></ProtectedRoute>}/>
                    <Route path="/management" element={<ProtectedRoute><Management/></ProtectedRoute>}/>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    </>);
}