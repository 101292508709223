import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import axios from "axios";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../component/AuthProvider";

export const Logout = () => {

    const {onLogout} = useAuth()

    useEffect(() => {
       onLogout()
    }, [])

    return (
        <Container>
            <Row>
                <Col className="mx-auto p-3" spancols={{span: 4, offset: 4}}>
                    <h1 className="my-5 mx-auto text-center"> Bis bald! </h1>
                    <Card className="mx-auto p-3">
                        Sie wurden erfolgreich ausgeloggt.
                        <Link to={"/"}>Zurück zum Login</Link>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};