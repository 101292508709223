import {Nav, Navbar} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {useAuth} from "./AuthProvider";

export const IKOMNavbar = () => {
    const {auth} = useAuth()

    return (
        <Navbar bg="primary" expand="lg" className="p-3">
            <Navbar.Brand as={Link} to="/"> <img
                src={"/IKOM_white.svg"}
                width="100"
                height="35"
                className="d-inline-block align-top"
                alt="IKOM logo"
            /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    {auth && <Nav.Link as={Link} to="/order">Bestellung</Nav.Link>}
                </Nav>
                <Nav className="me-auto">
                    {auth && <Nav.Link as={Link} to="/status">Auslastung</Nav.Link>}
                </Nav>
                <Nav>
                    {auth &&<Nav.Link as={Link} to="/logout">Logout</Nav.Link>}
                    {!auth &&<Nav.Link as={Link} to="/login">Login</Nav.Link>}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}