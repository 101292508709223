import {Button, ButtonGroup, Card, Col, Container, InputGroup, ListGroupItem, Row} from "react-bootstrap";

export type OrderItemProps = {
    id: number;
    name: string;
    amount: number;
    onIncrement: (id: number) => void;
    onDecrement: (id: number) => void;
}

export const OrderItem = (props: OrderItemProps) => {
    return (
        <ListGroupItem>
            <Row className="align-items-center">
                <Col>
                    {props.name}
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                    <Button className="increment" onClick={() => props.onDecrement(props.id)}>-</Button>
                    <span className={"mx-3"}>{props.amount}</span>
                    <Button className="increment"  onClick={() => props.onIncrement(props.id)}>+</Button>
                </Col>
            </Row>
        </ListGroupItem>
    );
};