import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {ChangeEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../component/AuthProvider";

export const Login = () => {
    const {onLogin, auth} = useAuth()
    const navigate = useNavigate()
    const [token, setToken] = useState<string>("")
    const [error, setError] = useState<string | undefined>(undefined)

    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get("token")
        console.log(token)
        if (token) {
           setToken(token)
        }
    }, [token, setToken])

    useEffect(() => {
        if (auth) {
            //navigate("/")
        }
    })

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setToken(e.target.value)
    }

    async function handleLogin() {
        if (!token) {
            return
        }

        const err = await onLogin(token);
        if (err) {
            setError(err.message);
        }
    }

    return (
        <Container>
            <Row>
                <Col className="mx-auto p-3"  md={{span: 6, offset: 3}}>
                    <h1 className="my-5 mx-auto text-center"> Login </h1>
                    <Card className="mx-auto p-3">
                        <Form.Group className="mb-3">
                            <Form.Label className="mx-auto test-center">
                                Den Tandem Token für den aktuellen Messetag eingeben.
                            </Form.Label>
                            <Form.Control placeholder="Token" onChange={handleChange} value={token}></Form.Control>
                        </Form.Group>
                        <Button className="secondary" onClick={handleLogin}>Anmelden</Button>
                        {error && <Alert className="mt-3" variant="warning">{error}</Alert>}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
