import {useAuth} from "./AuthProvider";
import {Navigate} from "react-router-dom";
import {Container, Row, Spinner} from "react-bootstrap";

export const ProtectedRoute = ({children}: { children: JSX.Element }) => {
    const {auth, loading} = useAuth();

    console.log(auth)

    if (loading) {
        return (
            <Container className="mt-5">
                <Row className="justify-content-center">
                    <Spinner animation="border"></Spinner>
                </Row>
            </Container>
        )
    }

    if (!auth) {
        return <Navigate to="/login" replace/>;
    }

    return children;
};