import React from 'react';
import './scss/custom.scss'
import ReactDOM from 'react-dom/client';
import {App} from "./pages/App";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
