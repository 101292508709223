import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, FormGroup, ListGroup, Row, Spinner} from "react-bootstrap";
import {OrderItem} from "../component/OrderItem";
import {useAuth} from "../component/AuthProvider";
import {cloneDeep} from "lodash";
import axios from "axios";


type Item = {
    id: number;
    name: string;
}

type LocalState = {
    items: Item[];
    booth: string;
    remark: string;
    amounts: Map<number, number>;
}

enum LoadingState {
    DATA,
    SENDING
}

enum AlertType {
    FAILURE_EMPTY,
    SUCCESS
}

type AlertState = {
    type: AlertType;
    msg: string;
}


const initState: LocalState = {
    items: [],
    booth: "",
    remark: "",
    amounts: new Map()
}


const Order = () => {

    const [state, setState] = useState<LocalState>(initState)
    const [loading, setLoading] = useState<LoadingState | undefined>(undefined)
    const [alert, setAlert] = useState<AlertState | undefined>(undefined)
    const {auth} = useAuth()

    useEffect(() => {
        setLoading(LoadingState.DATA)
        axios.get("/api/v1/items").then(
            data => setState({...state, items: data.data})
        )
        setLoading(undefined)
    }, [setState]);

    useEffect(() => {
        if (alert) {
            setTimeout(() => setAlert(undefined), 2000);
        }
    }, [alert, setAlert])

    const onIncrement = useCallback((id: number) => {
        const amounts = cloneDeep(state.amounts)
        amounts.set(id, (amounts.get(id) ?? 0) + 1)
        setState({...state, amounts})
    }, [setState, state])

    const onDecrement = useCallback((id: number) => {
        const amounts = cloneDeep(state.amounts)
        amounts.set(id, Math.max(0, (amounts.get(id) ?? 0) - 1))
        setState({...state, amounts})
    }, [setState, state])


    function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        console.log(e.target.value)
        setState({...state, remark: e.target.value});
    }

    function handleChangeBooth(e: React.ChangeEvent<HTMLInputElement>) {
        setState({...state, booth: e.target.value});
    }

    function sendOrder() {
        const items = [];
        for (let id of state.amounts.keys()) {
            const amount = state.amounts.get(id)
            if (amount !== 0) {
                items.push({id: id, amount})
            }
        }

        if (items.length === 0) {
            setAlert({
                type: AlertType.FAILURE_EMPTY,
                msg: "Bitte mind. eine Getränk oder Speise auswählen!"
            })
            return
        }

        const booth = state.booth
        if (!booth.match(/^\d\d?$/g)) {
            setAlert({
                type: AlertType.FAILURE_EMPTY,
                msg: "Bitte eine gültige Standnumber eingeben."
            })
            return
        }

        setLoading(LoadingState.SENDING);

        axios.post("/api/v1/order", {
            booth: state.booth,
            remark: state.remark,
            items: items
        }).then(res => {
            if (res.status === 201) {
                setAlert({type: AlertType.SUCCESS, msg: res.statusText})
                resetOrder()
            }
        })

        setLoading(undefined)
    }

    function resetOrder() {
        setState({...state, booth: "", remark: "", amounts: new Map()})
    }

    function renderItem(item: Item) {
        return <OrderItem id={item.id} name={item.name}
                          amount={state.amounts.get(item.id) ?? 0}
                          onIncrement={onIncrement}
                          onDecrement={onDecrement}/>
    }

    return (
        <Container className="p-3">
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <h1 className="my-5 mx-auto text-center">Bestellung</h1>
                    <h3 className="my-4">Name: {auth ? auth.name : ""}</h3>
                    <Card className="p-3 mb-2">
                        <Row className="align-items-center">
                            <FormGroup>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Stand</Form.Label>
                                        <Form.Control type="text"
                                                      value={state.booth}
                                                      onChange={handleChangeBooth}/>
                                    </Form.Group>
                                </Form>
                            </FormGroup>
                        </Row>
                    </Card>

                    <Card className="p-3 mb-2">
                        <ListGroup variant="flush">
                            {state.items.map(renderItem)}
                        </ListGroup>
                    </Card>


                    {loading === LoadingState.DATA ?
                        <Spinner animation="grow"></Spinner>
                        : <Card className="p-3 mb-2">
                            <Row className="align-items-center">
                                <FormGroup>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Anmerkung</Form.Label>
                                            <Form.Control as="textarea"
                                                          value={state.remark}
                                                          onChange={handleOnChange}
                                                          rows={2}/>
                                        </Form.Group>
                                    </Form>
                                </FormGroup>
                            </Row>
                        </Card>
                    }

                    {alert?.type === AlertType.FAILURE_EMPTY &&
                        <Alert className="px-3" variant="warning">
                            {alert.msg}
                        </Alert>
                    }
                    {alert?.type === AlertType.SUCCESS &&
                        <Alert className="px-3" variant="success">
                            Bestellung erfolgreich übermittelt!
                        </Alert>
                    }
                    <div className="d-flex flex-column">
                        <Button
                            onClick={sendOrder}>
                            {loading == LoadingState.SENDING ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"

                                    aria-hidden="true"/>
                                : "Bestellen"}</Button>
                    </div>

                </Col>
            </Row>
        </Container>
    );
}

export default Order;
