import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Form, ProgressBar, Row, Spinner} from "react-bootstrap";
import axios from "axios";

type LocalState = {
    status: number;
    time: number;
    update: number;
}

export type Status = {
    from: number;
    message: string;
}

export const possibleStatus = [
    {
        from: 0,
        message: "Alle Plätze frei"
    },
    {
        from: 20,
        message: "Sehr viele Plätze frei"
    },
    {
        from: 30,
        message: "Noch mehr als die Hälfte frei"
    },
    {
        from: 50,
        message: "Weniger als die Hälfte frei"
    },
    {
        from: 75,
        message: "Noch einige Plätze frei"
    },
    {
        from: 85,
        message: "Noch wenige Plätze frei"
    },
    {
        from: 90,
        message: "Fast alle Plätze belegt"
    },
    {
        from: 100,
        message: "Alles belegt"
    }
];

const initState: LocalState = {
    status: 56,
    time: 1589839289,
    update: 0
}


const Status = () => {

    const [state, setState] = useState<LocalState>(initState)

    useEffect(() => {
        axios.get("/api/v1/status").then(
            res => setState({...state, status: res.data.status, time: parseInt(res.data.time)})
        )
    }, [setState]);

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        setState({...state, update: parseInt(e.target.value) ?? 0});
    }

    function sendStatus() {
        axios.post("/api/v1/status", {status: state.update}).then(res => {
            if (res.status === 200) {
                setState({...state, status: res.data.status, time: parseInt(res.data.time)})
            }
        })
    }

    return (
        <>
            <Container className="p-3">
                <Row>
                    <Col md={{span: 6, offset: 3}}>
                        <h1 className="my-5 mx-auto text-center">Auslastung</h1>
                        <Card className="p-3 mb-2">
                            <Row className="align-items-center">
                                <h4>{state.status} % {getStatus(state.status, possibleStatus)}</h4>
                                <div><ProgressBar now={state.status}
                                                  variant={state.status < 50 ? "success" :
                                                      state.status < 75 ? "warning" : "danger"}/></div>
                                <div className="mt-2">Last updated: {new Date(state.time * 1000).toLocaleTimeString()}
                                </div>
                            </Row>
                        </Card>

                        <h2 className="my-5 mx-auto text-center">Auslastung melden</h2>
                        <Card className="p-3 mb-2">
                            <Row>
                                <div>
                                    <h4>{state.update} % {getStatus(state.update, possibleStatus)}</h4>
                                </div>
                                <Form>
                                    <Form.Range defaultValue={0} max={100} min={0} onChange={handleOnChange}>
                                    </Form.Range>
                                </Form>
                            </Row>
                        </Card>

                        <div className="mt-4 d-flex flex-column">
                            <Button onClick={sendStatus}>Melden</Button>
                        </div>

                    </Col>
                </Row>

            </Container>
        </>
    )
        ;
}

export default Status;


export function getStatus(status: number, possibleStatus: Status[]) {
    return possibleStatus.sort((a, b) => b.from - a.from)
        .find(s => s.from <= status)?.message
}