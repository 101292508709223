import {Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";


type Item = {
    id: number;
    name: string;
}

type Token = {
    id: number;
    token: string;
}

type LocalState = {
    items: Item[]
    tokens: Token[]
}

export const Management = () => {

    const [state, setState] = useState<LocalState>({items: [], tokens: []})
    const [name, setName] = useState<string>("")

    useEffect(() => {
        axios.get("/api/v1/items").then(
            res => setState((prevState) => {
                return {...prevState, items: res.data}
            })
        )
        axios.get("/api/v1/token").then(
            res => setState((prevState) => {
                return {...prevState, tokens: res.data}
            })
        )
    }, [setState]);

    function handleDeleteItem(id: number) {
        axios.delete(`/api/v1/items/${id}`).then(
            res => setState({...state, items: res.data})
        )
    }

    function handleDeleteToken(id: number) {
        axios.delete(`/api/v1/token/${id}`).then(
            res => setState({...state, tokens: res.data})
        )
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setName(e.target.value)
    }

    function handleAddItem() {
        axios.post(`/api/v1/items/`, {name}).then(
            res => {
                setState({...state, items: res.data})
            }
        )
    }

    function handleAddToken() {
        axios.post(`/api/v1/token/generate`).then(
            res => {
                setState({...state, tokens: res.data})
            }
        )
    }

    return (
        <Container>
            <Row>
                <Col className="mx-auto p-3" spancols={{span: 4, offset: 4}}>
                    <h1 className="my-5 mx-auto text-center"> Speisen & Getränke </h1>
                    <Card className="mx-auto p-3">
                        <ListGroup variant="flush">
                            {state.items.map((item) =>
                                <ListGroupItem key={item.id}>
                                    <Row className="justify-content-between">
                                        <Col>
                                            {item.name}
                                        </Col>
                                        <div style={{width: "auto"}} className="d-inline"></div>
                                        <Button as={"span"}
                                                style={{width: "auto"}}
                                                onClick={() => handleDeleteItem(item.id)}>Delete</Button>
                                    </Row>
                                </ListGroupItem>)}
                            <ListGroupItem key="new-item" className="pt-3">
                                <Form.Control value={name} onChange={handleChange}
                                              placeholder="Neue Speise oder Getränk"/>
                                <div className="d-flex justify-content-center">
                                    <Button className="mt-2" type="submit" onClick={handleAddItem}>Hinzufügen</Button>
                                </div>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="mx-auto p-3" spancols={{span: 4, offset: 4}}>
                    <h1 className="my-5 mx-auto text-center"> Login Token </h1>
                    <Card className="mx-auto p-3">
                        <ListGroup variant="flush">
                            {state.tokens.map((token) =>
                                <ListGroupItem key={token.id}>
                                    <Row className="justify-content-between">
                                        <Col>
                                            {token.token}
                                        </Col>
                                        <div style={{width: "auto"}} className="d-inline"></div>
                                        <Button as={"span"}
                                                style={{width: "auto"}}
                                                onClick={() => handleDeleteToken(token.id)}>Delete</Button>
                                    </Row>
                                </ListGroupItem>)}
                            <ListGroupItem key="new-item" className="pt-3">
                                <div className="d-flex justify-content-center">
                                    <Button className="mt-2" type="submit" onClick={handleAddToken}>Neuer Token</Button>
                                </div>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}