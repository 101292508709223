import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    ListGroup,
    ProgressBar,
    Row,
    Spinner
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getStatus, possibleStatus} from "./Status";
import axios from "axios";
import {useAuth} from "../component/AuthProvider";
import {Link} from "react-router-dom";

type LocalState = {
    status: number;
    time: number;
}

const initState = {
    status: 0,
    time: 0
}

export const Home = () => {

    const [state, setState] = useState<LocalState>(initState)
    const {auth} = useAuth()

    useEffect(() => {
        const refresh = async () => {
            axios.get("/api/v1/status").then(
                res => setState({...state, status: res.data.status, time: parseInt(res.data.time)})
            )
        }
        refresh().then();

        const interval = setInterval(async () => {
            await refresh()
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [setState]);

    return (
        <Container className="p-3">
            <Row>
                <Col md={{span: 6, offset: 3}}>
                    <h3 className="my-4">Hey, {auth ? auth.name : ""}</h3>
                    <h1 className="my-4 mx-auto text-center">Auslastung</h1>
                    <Card className="p-3 mb-2">
                        <Row className="align-items-center">
                            <h4>{state.status} % {getStatus(state.status, possibleStatus)}</h4>
                            <div><ProgressBar now={state.status}
                                              variant={state.status < 50 ? "success" :
                                                  state.status < 75 ? "warning" : "danger"}/></div>
                            <div className="mt-2">Last updated: {new Date(state.time * 1000).toLocaleTimeString()}
                            </div>
                        </Row>
                    </Card>
                    <h1 className="my-4 mx-auto text-center">Bestellung</h1>
                    <Link to="order">
                        <Card className="p-3 mt-3 mb-2">
                            <Button className="align-items-center">
                                <h2 className="text-center">Zur Bestellung</h2>
                            </Button>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}